.currency {
    float: left;
    border: 0px solid green;
    margin-right: 5px;
  }

 .flexTable .value{
   float: left;
   border: 0px solid red;
 }

 #uploadedFile{
   font-size: 0.8em;
   text-align: left;
   font-weight: bold;
   margin-top: -20px!important;
   padding: 0;
   float: left;
   display: inline-block;
   color: green;
}
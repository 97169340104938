.sidebar {
    background-color: #2a304a;
    display: flex;
    flex-direction: column;
    width: 244px;
    transition: margin-left 250ms ease-out;
    will-change: margin-left;
    position: absolute;
    height: 100%;
    z-index: 29;
    // box-shadow: 0 0 13px 1px hsla(201, 23%, 17%, 0.53);
    box-shadow: 0 0 5px 1px rgba(33, 46, 53, .53);

    .logo {
        align-items: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 0px;
        cursor:pointer;

        .txt {
            padding-left: 20px;
            user-select: none;
            color: #fff;
            font-size: 18px;
        }
    }

    &.is-collapsed {
        margin-left: -244px;
    }
}

.sidebar-background {
    cursor: pointer;
    background: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 28;

    &.is-closed {
        display: none;
    }
}

.side-burger {
    cursor: pointer;
    fill: #fff;
    margin-right: 25px;
    height: 16px;
    width: 25px;
}

.hide-sidebar .side-burguer-toggle {
    display: none;
}

.side-burguer-toggle svg {
    display: block;
    fill: #191f23;
    height: 16px;
    width: 16px;
}

.sidebar-nav {
    color: #d5dce0;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 10px;

    .item,
    a {
        color: unset;
        align-items: center;
        display: flex;
        fill: #9ca6af;
        justify-content: flex-start;
        padding: 7px 15px;
        color: #dfe6ea;
        font-weight: 400;

        &:active,
        &:focus,
        &:hover {
            color: unset;
            text-decoration: unset;
        }

        &:hover,
        &.active {
            background-color: #23283a;
        }


        a {
            padding: 5px 1px;
        }

        ul {
            padding: 7px 15px;
        }

        .name {
            margin-right: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .icon {
            width: 18px;
            fill: #646f79;
            margin-right: 10px;
            transition: transform 250ms ease-out;
        }

        .sidebar-dropdown .sidebar-submenu ul {
            padding: 5px 0;
            margin-left: 10px;
        }

        .sidebar-dropdown .sidebar-submenu li {
            padding-left: 15px;
            font-size: 13px;
            border-left: solid;
            border-left-width: thin;

        }


        .sidebar-dropdown .open {
            transform: rotate(90deg);
            right: 17px;
        }

    }
}

@include desktop() {
    .sidebar {
        position: initial;
        height: auto;
        z-index: 1;
    }

    .sidebar-background {
        display: none;
    }
}
.task-list {
    display: flex;
    height: calc(100% - 40px);

    .container-row {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        flex: 0 0 100%;
        min-width: 1px;
        width: 100%;
        flex-direction: row;
    }

    .board-column {
        display: flex;
        flex-direction: column;
        transition: box-shadow 250ms;
        box-sizing: border-box;
        height: 100%;
        background-color: #f0f0f0;
        margin-right: 20px;
        position: relative;
        width: 300px;
        box-shadow: 0px 2px 1px 0px hsla(201, 23%, 17%, 0.15);

        .board-header {
            flex: 0 0 30px;
            border-top: 5px solid;
            padding: 0;
            align-items: center;
            display: flex;
            position: relative;

            span {
                margin-right: auto;
                font-size: 16px;
                display: inline-block;
                line-height: 30px;
                overflow: hidden;
                position: relative;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-left: 10px;
            }
        }

        .board-container {
            display: flex;
            flex: 1 1 100%;
            flex-direction: column;
            padding: 10px 10px 0;
            min-height: 0;
            position: relative;
            overflow-x: hidden;
            height: 700px;
            max-height: 700px;
            overflow-y: scroll;
        }
    }

    .sortable-item {
        flex: 0 0 auto;
        padding-bottom: 15px;
        margin: 0;

        &.is-dragging {
            .sortable-card {
                background: rgba(224, 230, 232, 0.8);
                box-shadow: none;

                * {
                    visibility: hidden;
                }
            }
        }
    }

    .status {
        float: left;
        margin-right: 0.5em;
        padding: 8px 15px 4px;
        background: #0079bf;
        color: #ffffff;
        border-radius: 4px;
        text-align: center;
        text-transform: uppercase;
        font-size: 12px;
        margin-top: -4px;

        &.paid {
            background: #61bd4f;
        }

        &.approved {
            background: #ff9f1a;
        }

        &.published {
            background: #61bd4f;
        }

        &.notpublished {
            background: #ff9f1a;
        }
    }

    i.fas.fa-clipboard-check {
        padding-right: 5px;
    }

    .sortable-card {
        align-items: center;
        background: #fff;
        border: 1px solid transparent;
        box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        transition: box-shadow 100ms, transform 100ms, background-color 100ms,
            border-color 100ms;
        cursor: pointer;
        display: block;
        position: relative;

        &:hover {
            box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
            transform: translateY(-1px);
        }

        .contents {
            padding: 10px;
        }

        .project{
            white-space: nowrap;
            width: 110px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        
        .metadata {
            display: flex;
            justify-content: space-between;

            .project,
            .dueDate {
                font-size: 12px;
                color: #646f79;
            }

            .dueDate {
                span {
                    color: #17bb50;
                }

                &.past span {
                    color: #ed4758;
                }

                &.soon span {
                    color: #d47512;
                }
            }
        }

        .card-title {
            margin-bottom: 20px;
            font-size: 14px;
            font-weight: 400;
            line-height: 1em;
            flex-grow: 1;
            min-width: 1px;
            word-wrap: break-word;

            &::after {
                content: '\200b';
            }
        }
    }
}
@import 'utils';
@import 'minireset';
@import 'datepicker';
@import 'form';
@import 'header';
@import 'sidebar';
@import 'spacing';
@import 'cards';
@import 'quickview';

::-moz-selection {
    color: white;
    background: #2a304a;
}

::selection {
    color: white;
    background: #2a304a;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#main {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.bdn {
    border: none;
}

.hide-desc {
    margin-top: 10px;
}

.panel {
    display: flex;
    flex: 1;
}

.column.form {
    margin-top: 10px;
}

.float-left{
    float:left;
}

.button {
    text-transform: uppercase;
    -webkit-box-shadow: 0 2px 5px 0 #dbdbdb;
    box-shadow: 0 2px 5px 0 #dbdbdb;
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
    border-color: #ffffff;
    &:hover{
        border-color: #f8f8f8;
        background-color: #f8f8f8;
        box-shadow: 0 4px 10px 0px #dbdbdb;
        -webkit-box-shadow: 0 4px 10px 0px #dbdbdb;
    }
    &.is-success {
        background-color: #2A304A;
        color: #fff;

        &:hover {
            background-color: #191f23;
        }

        &:focus:not(:active) {
            box-shadow: 0 0 0 0.125em #2A304A;
        }

        &:active {
            background-color: #47565f;
        }
    }

    &.is-link {
        background-color: #4b93e3;

        &:hover,
        &.is-hovered {
            background-color: #1A77C9;
        }

        &:active,
        &.is-active {
            background-color: #1A77C9;
        }

        &:focus:not(:active) {
            box-shadow: 0 0 0 0.125em #1A77C9;
        }
    }
    &.rascunho{
        background-color:#11af48;

        &:hover {
            background-color: #075f26;
            border-color:transparent;
        }

        &:focus:not(:active) {
            box-shadow: 0 0 0 0.125em #11af48;
        }

        &:active {
            background-color: #11af48;
        }
    }
    &.arquivar {
        background-color: #8a0808;

        &:hover {
            background-color: #6b0707;
        }

        &:focus:not(:active) {
            box-shadow: 0 0 0 0.125em rgb(138, 8, 8);
        }

        &:active {
            background-color: #3e0101;
        }
    }

    &.is-info {
        background-color: #ffffff;
        color:#2a304a;

        &:hover {
            background-color: #f3f3f3;
            color:#2a304a
        }

        &:active,
        &.is-active,
        &.is-link {
            background-color: #47565f;
        }

        &:focus:not(:active) {
            box-shadow: 0 0 0 0.125em rgb(43, 43, 43);
        }
    }

    &.is-danger {
        background-color: #8a0808;

        &:hover {
            background-color: #6b0707;
        }

        &:active {
            background-color: #3e0101;
        }

        &:focus:not(:active) {
            box-shadow: 0 0 0 0.125em rgb(138, 8, 8);
        }
    }



}

.button.is-medium{
    height: 36px;
    padding: 0 16px;
    line-height: 36px;
    font-size: 14px;
}

.button.is-large{
    font-size:15px!important;
    margin: 0 4px 0 2px;
    height: 48px;
    line-height: 48px;
}

.far-way {
    padding-top: 1rem;
    padding-right: 2rem;
}

.dropzone {
    outline: none;
    cursor: pointer;
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    border-radius: 5px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    margin-bottom: 15px;

    &:hover {
        border-color: #4693e0;
    }

    &.active {
        border-style: solid;
    }
}

.modal-form {
    position: absolute;
    bottom: auto;
    top: 0;
    z-index: 30;
    min-height: 100vh;
}

.modal,
.message {
    pre {
        white-space: pre-wrap;
        word-wrap: break-word;
        margin-top: 15px;
        padding: 10px;
        background-color: #ffe2e8;
        border-radius: 5px;
        color: #db264a;
    }
}

.responsive-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.modal {
    display: flex;
    visibility: hidden;
    max-height: 100vh;

    &.modal-action {
        z-index: 80;
    }

    .modal-background {
        transition: all 0.3s;
        opacity: 0;
        position: fixed;
    }

    .modal-full,
    .modal-content {
        transform: scale(0.7);
        opacity: 0;
        transition: all 0.3s;
    }

    .modal-full .modal-close {
        background: transparent;

        &::before,
        &::after {
            background: #000;
        }

        &:hover {
            background: transparent;
        }
    }

    &.is-active {
        max-height: initial;
        visibility: visible;

        .modal-background {
            opacity: 1;
        }

        .modal-full,
        .modal-content {
            transform: scale(1);
            opacity: 1;
        }
    }
}

.modal-full {
    max-width: 910px;
    width: 90%;
    margin: 30px 0;
    position: relative;
}

.no-wrap {
    white-space: nowrap;
}

.no-width {
    width: 1%;
}

.table tr td .buttons {
    display: block;
}

.flex-filter {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;


}

.filterbox {
    margin-bottom: 1em;
    border-radius:0;
}

.filtertop {
    margin-top: 10px;
    font-size: 14px
}

.filterbtn {
    margin-right: 8px;
}

.content {
    height: 100%;
    padding: 15px 20px 50px 20px;
    background-color: #f9f9f9;
    margin-bottom: 0 !important;
}

footer.footerPanel {
    display: none;
    color: #2A304A;
    background-color: #f0f0f0;
    text-align: center;
    padding: 10px 20px;
    border-top: 2px solid #e8e8e8;
    font-style: italic;
}

body {
    color: #151b26;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
}

a,
p a {
    color: #14aaf5;
    text-decoration: none;
}

.remove-archived {
    text-align: right;
    margin-top: -15px;
    margin-bottom: 10px;

    a {
        text-decoration: none;
        font-size: 12px;
        color: #aeaeae;
    }
}

.title-tag {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.title{
    font-weight:normal!important;
}

.tag {
    margin-right: 10px;
}

.pagamento {
    .panel {
        float: none;
        width: 25%;
        display: inline-block;
        margin: 0 15px 0 0;
    }

    p.panel-heading {
        margin: 0;

        span.name {
            cursor: pointer;
        }
    }

    .tag {
        padding: 15px;
        float: right;
        font-size: 13px;
        user-select: none;

        &:not(body).is-warning {
            background-color: #e89117;
            color: #fff;
            padding: 5px;
            height: 1.7em;
        }

        &:not(body).is-success {
            background-color: #56ab46;
            color: #fff;
            padding: 5px;
            height: 1.7em;
        }
    }

    .tasks {
        display: flex;
        flex-wrap: wrap;
    }

    .is-success {
        background-color: #11af48 !important;
    }
}

.dialog--nux {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #2a304a;
    min-height: 100vh;
}

.dialog--nux-container {
    flex-shrink: 0;
    margin: 0 auto;
    width: 95%;
}

.form-label.is-right {
    justify-content: flex-end;
    text-align: right;
}

@media (min-width: 48em) {
    .dialog--nux-container {
        width: 585px;
    }
}

.dialog--nux-header {
    flex-shrink: 0;
    margin: 45px 0 30px -5px;

    p {
        color: #fff;
        margin-top: 5px;
    }
}

@media (min-width: 48em) {
    .dialog--nux-header {
        margin-top: 15px;
    }
}

.dialog--nux-logo {
    user-select: none;
    text-align: center;
    color: #fff;
    font-size: 45px;
}

.dialog--nux-after {
    flex: 0 1 64px;
}

.dialog--nux-content {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    line-height: 1.5;

    .title,
    .subtitle {
        color: #151b26;
        font-weight: 400;
        text-align: center;
    }

    .title {
        color: #151b26;
        font-size: 32px;
        line-height: 40px;
        padding: 45px 30px 30px;
        text-transform: none;

        +form .form-input:first-of-type {
            margin-top: 0;
        }
    }
}

.dialog--nux-inner {
    padding: 0 30px;
}

@media (min-width: 48em) {
    .dialog--nux-inner {
        padding: 0 90px;
    }
}

form.dialog--nux-inner {
    padding-bottom: 45px;
}

.dialog--nux--wordless-seperator {
    border-top: 1px solid #e0e6e8;
    margin-top: 30px;
}

.dialog--nux-contents-hidden {
    display: none;
}

.dialog--nux-saml-announcment {
    margin-bottom: 30px;
}

.dialog--nux-saml-switch-back-link {
    margin-top: 30px;

    a {
        border-bottom: 1px dotted #848f99;
        color: #848f99;

        &:hover {
            border-bottom: 1px solid #848f99;
            text-decoration: none;
        }
    }
}

.dialog--nux-note {
    font-size: 16px;
    margin-bottom: 30px;
}

.dialog--nux-confirmation {
    color: #646f79;
    padding-bottom: 30px;
    text-align: center;
}

.dialog--nux-recommendedBrowsers {
    margin: 30px auto;

    td {
        height: 84px;
        padding-right: 30px;
        text-align: center;
        vertical-align: bottom;

        &:last-of-type {
            padding-right: 0;
        }

        img {
            display: block;
            margin-bottom: 10px;
        }
    }
}

.dialog--nux-recommendedBrowsers-footnote {
    padding-bottom: 30px;
}

.dialog--nux-terms {
    color: #848f99;
    display: block;
    font-size: 11px;
    margin-right: 1em;
    margin-bottom: 15px;
    text-align: center;
}

@media (min-width: 30em) {
    .dialog--nux-terms {
        display: inline;
        text-align: right;
    }
}

.dialog--nux-marketing {
    margin: 30px 0;

    p {
        margin-bottom: 15px;
    }

    ul {
        margin-left: 15px;
    }

    li {
        list-style: circle;
        line-height: 1.5;
        text-align: left;
    }
}

.dialog--top-error {
    padding-bottom: 30px;
}

ul.anexos {
    flex: 1 1 auto;
    min-width: 1px;
    margin: 0 !important;

    li {
        align-items: center;
        display: flex;
        font-size: 12px;
        margin-bottom: 10px;

        a {
            flex: 0 1 auto;
            margin-bottom: -1px;
            min-width: 1px;
            word-wrap: break-word;
        }

        &:hover .delete-button {
            visibility: visible;
        }
    }

    .file-icon {
        align-self: flex-start;
        fill: #b7bfc6;
        flex-shrink: 0;
        margin-right: 5px;
        height: 16px;
        width: 16px;
    }

    .delete-button {
        cursor: pointer;
        flex-shrink: 0;
        margin: 0 15px;
        position: relative;
        visibility: hidden;
        align-items: center;
        background: #848f99;
        border-radius: 50%;
        display: inline-flex;
        fill: #fff;
        justify-content: center;
        padding: 2px;

        svg {
            height: 10px;
            width: 10px;
        }
    }
}

@keyframes pulse-me {
    0% {
        transform: scale(0.3);
        opacity: 0;
    }

    50% {
        opacity: 0.1;
    }

    70% {
        opacity: 0.09;
    }

    100% {
        transform: scale(3);
        opacity: 0;
    }
}

.badge.navbar {
    position: initial;
    font-size: 20px;

    li {
        float: left;
        margin: 0;
        padding: 0 1.2rem;
        display: flex;
        align-items: center;
        position: relative;

        >a {
            color: #2A304A;
        }
    }

    .button-badge {
        border-radius: 2px;
        padding: 1px 3px;
        font-size: 10px;
        position: absolute;
        background: #ff9f1a;
        right: 8px;
        top: -8px;
    }

    .dropdown-menu {
        top: 100%;
        left: 0;
        right: 0;

        span {
            white-space: nowrap;
            display: block;
            font-size: 15px;
            padding: 0 15px 5px;
        }

        .dropdown-item {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            cursor: initial;

            &.is-link .notification-content {
                cursor: pointer;
            }

            &.is-important {

                .notification-heading,
                .notification-timestamp {
                    color: #61bb51;
                }
            }

            .notification-heading {
                line-height: 1.25;
                color: #6a707e;
                width: 20em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .notification-timestamp {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-top: 0.3rem;
                line-height: 1.25;
                color: #abafb3;
            }

            .notify-close {
                margin-left: 1.2rem;
                cursor: pointer;
                opacity: 0.3;

                i {
                    display: block;
                    margin: 0;
                }
            }
        }
    }
}

.logo {
    text-align: center;
    justify-content: space-between;
    align-items: center;
}
.logo.login {
    user-select: none;
    width:30%;
}

.quill,
.quill-box,
.quill * {
    font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Arial,
        sans-serif;
}

.quill-box {
    background-color: #eee;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-size: 12px;
    padding: 3px 10px;
    margin-top: -1px;
    z-index: 5;
    position: relative;
    display: flex;
    justify-content: space-between;

    .help {
        margin: 0;
    }
}

.ql-container {
    min-height: 300px;
}

.ql-snow .ql-tooltip,
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    z-index: 10;
}

.ql-editor {
    height: 300px;
    font-size: 1.3em;
}

.ql-editor h1 {
    line-height: 2em;
    padding-bottom: 0.5em;
}

.ql-editor h2 {
    padding-bottom: 0.5em;
    line-height: 2em;
}

.ql-editor h3 {
    padding-bottom: 0.5em;
    line-height: 2em;
}

.ql-editor p {
    padding-bottom: 0.5em;
    font-size: 15px;
    line-height: 1.5em;
}

.ql-editor ol,
.ql-editor ul {
    padding-bottom: 0.5em;
    font-size: 15px;
    line-height: 1.5em;
}

.persona-box {
    &.box {
        background-color: #f5f5f5;
    }

    h4 {
        font-size: 1.1rem;
    }

    h6 {
        font-size: 0.85rem;
        margin-bottom: 10px;
    }

    .column {
        padding-bottom: 0;
    }

    .persona-info {
        margin-top: 20px;
        white-space: pre-line;
    }
}

.box{
    background: rgb(255,255,255);
    box-shadow: 0 1px 4px 0 #f1f1f1;
    -webkit-box-shadow: 0 1px 4px 0 #f1f1f1;
}

.button,
.file-cta,
.file-name,
.input,
.pagination-ellipsis,
.pagination-link,
.pagination-next,
.pagination-previous,
.select select,
.textarea {
    border-radius: 0px;
    transition: all 0.25s cubic-bezier(0.23, 1, 0.32, 1) 50ms;
}

@include tablet() {
    .badge.navbar {
        position: relative;

        .dropdown-menu {
            top: calc(100% - 3px);
            right: 0;
            left: auto;
        }
    }
}

@media only screen and (max-width:740px) {

    .quickview-header,
    .quickview {
        display: none !important;
    }
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 75%;
}
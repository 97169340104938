
.quickview {
    display: flex;
    flex-direction: column;
    background-color: #f6f8f9;
    max-width: calc(100% - 50px);
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 35;
    transform: translateZ(0);
    transition: .3s ease;
    backface-visibility: hidden;
    perspective: 1000;
    will-change: transform;

    &.is-marginless {
        max-width: 100%; }

    @include tablet {
        width: 50%;
        right: -50%; }

    @include desktop {
        width: 35%;
        right: -35%; }

    @include widescreen {
        width: 30%;
        right: -30%; }

    @include fullhd {
        width: 25%;
        right: -25%; }

    &.is-left {
        @include tablet {
            left: -50%; }
        @include desktop {
            left: -35%; }
        @include widescreen {
            left: -30%; }
        @include fullhd {
            left: -25%; } }

    &.is-active {
        right: 0;
        box-shadow: 5px 0px 13px 3px rgba( 0, 0, 0, 0.1 );

        &.is-left {
            left: 0; } } }

.quickview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    min-height: 3.25em !important;
    border-bottom: 1px solid hsl(0, 0%, 86%);
    background-color: #2a304a;

    .title {
        font-size: 18px;
        margin-bottom: 0 !important;
        color: #fff;

        img {
            max-height: 2em; } } }

.quickview-body {
    -webkit-box-flex: 1;
    flex: 1 1 0%;
    overflow-y: auto; }

.quickview-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    min-height: 4rem;
    background-color: hsl(0, 0%, 96%);
    border-top: 1px solid hsl(0, 0%, 86%);

    > * {
        margin: 0 .4rem; } }

.quickview-block {
    padding: 20px; }


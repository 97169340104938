$gap: 64px;
$tablet: 769px;
$desktop: 1025px;
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);

@mixin tablet {
    @media screen and (min-width: $tablet), print {
        @content; } }

@mixin desktop {
    @media screen and (min-width: $desktop) {
        @content; } }

@mixin widescreen {
    @media screen and (min-width: $widescreen) {
        @content; } }

@mixin fullhd {
    @media screen and (min-width: $fullhd) {
        @content; } }

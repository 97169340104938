@keyframes show-navbar-dropdown {
    0% {
        transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
        transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
        opacity: 0;
    }

    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

.page-topbar {
    background: #f9f9f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    padding: 10px 20px;
    position: relative;

    &.hide-sidebar {
        justify-content: flex-end;
    }

    .right {
        display: flex;
    }
}

.navbar {
    flex-direction: row;
    display: flex;
    align-items: center;
    list-style: none;
    background: transparent;
    z-index: 27;

    .dropdown {
        display: inline-block;
        position: relative;
    }

    .nav-link {
        color: rgb(21, 27, 38);
        display: block;
        padding-left: 1rem;
    }

    .media {
        color: #191f23;
        border: 2px solid #191f23;
        padding: 5px;
        display: flex;
        align-items: center;
        transition: all 0.25s cubic-bezier(0.23, 1, 0.32, 1) 50ms;

        &:hover {
            border-color: #151d21;
            background: #2A304A;
            color: #dfe6ea;
        }
    }

    .avatar {
        display: inline-flex;
        color: #fff;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        font-size: 0.875rem;
        width: 40px;
        height: 40px;

        img {
            width: 100%;
            border-radius: 50%;
        }
    }

    .media-body {
        flex: 1 1;
        margin-left: 0.5rem;

        span {
            margin-bottom: 0;
            font-size: 0.875rem;
            font-weight: 600;
        }
    }

    .dropdown-menu {
        position: absolute;
        margin: 0;
        pointer-events: none;
        opacity: 0;
        min-width: 12rem;
        font-size: 1rem;
        z-index: 1000;
        top: calc(100% + 8px);
        right: 0;
        left: auto;
        display: none;
        float: left;
        padding: 0.5rem 0;
        list-style: none;
        text-align: left;
        color: #525f7f;
        border: 0 solid rgba(0, 0, 0, 0.15);
        border-radius: 0.4375rem;
        background-color: #fff;
        background-clip: padding-box;
        box-shadow: 0 0 100px rgba(50, 50, 93, 0.1),
            0 5px 35px rgba(50, 50, 93, 0.15), 0 5px 5px rgba(0, 0, 0, 0.1);

        &.show {
            animation: show-navbar-dropdown 0.25s ease forwards;
            pointer-events: auto;
            opacity: 1;
            display: block;
        }

        .dropdown-item {
            font-size: 0.875rem;
            padding: 0.5rem 1rem;
            font-weight: 400;
            display: block;
            clear: both;
            width: 100%;
            text-align: inherit;
            white-space: nowrap;
            color: #212529;
            border: 0;
            background-color: transparent;

            i {
                font-size: 1rem;
                margin-right: 1rem;
                vertical-align: -17%;
            }

            &:focus,
            &:hover {
                text-decoration: none;
                color: #16181b;
                background-color: #f2f2f2;
            }

            &:active {
                text-decoration: none;
                color: #16181b;
                background-color: transparent;
            }
        }

        .dropdown-divider {
            overflow: hidden;
            height: 0;
            margin: 0.5rem 0;
            border-top: 1px solid #e9ecef;
        }
    }

    .dropdown-header {
        font-size: 0.625rem;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        padding: 0.5rem 1rem;
        white-space: nowrap;
        color: #8898aa;

        h6 {
            margin-bottom: 0;
        }

        button span {
            padding: 0 !important;
            font-size: 0.75rem !important;
        }
    }
}
.form-input {
    margin-top: 10px;
    text-align: left;
    position: relative;

    input,
    select,
    textarea {
        background-color: #fff;
        border: 1px solid #d5dce0;
        border-radius: 2px;
        color: #151b26;
        margin: 0;
        padding: 0 0 0 10px;
        width: 100%;

        &[readOnly] {
            padding: 0; } }

    input, select {
        height: 38px; }

    textarea {
        resize: vertical;
        max-height: 600px;
        min-height: 120px;
        padding: 10px; }

    input:focus,
    select:focus,
    textarea:focus {
        outline: 0; }

    input[disabled] {
        background-color: #f6f8f9;
        border-color: #edf1f2;
        box-shadow: none;
        cursor: default;

        &:hover,
        &:active,
        &:focus {
            background-color: #f6f8f9;
            border-color: #edf1f2;
            box-shadow: none;
            cursor: default; } }

    select[disabled] {
        background-color: #f6f8f9;
        border-color: #edf1f2;
        box-shadow: none;
        cursor: default;
        &:hover,
        &:active,
        &:focus {
            background-color: #f6f8f9;
            border-color: #edf1f2;
            box-shadow: none;
            cursor: default; } }

    textarea[disabled] {
        background-color: #f6f8f9;
        border-color: #edf1f2;
        box-shadow: none;
        cursor: default;
        &:hover,
        &:active,
        &:focus {
            background-color: #f6f8f9;
            border-color: #edf1f2;
            box-shadow: none;
            cursor: default; } }

    input {
        line-height: normal;

        &:focus {
            border-color: #b7bfc6;
            box-shadow: inset 0px 1px 1px 0px #edf1f2; }

        &:read-only {
            border: none; }

        &[type='checkbox'],
        &[type='radio'],
        &[type='range'],
        &[type='file'],
        &[type='range'],
        &[type='color'] {
            height: auto;
            padding-left: 0;
            width: auto; }

        &[type='checkbox']:focus,
        &[type='radio']:focus,
        &[type='range']:focus,
        &[type='file']:focus,
        &[type='range']:focus,
        &[type='color']:focus {
            border-color: none;
            box-shadow: none;
            outline: none; } }

    label {
        color: #848f99;
        font-size: 12px;
        font-weight: 500;
        display: block;
        margin-bottom: 7px; }

    &.is-danger {
        label {
            color: hsl(348, 100%, 61%); }

        input,
        select,
        textarea {
            border-color: hsl(348, 100%, 61%); } } }

.form-label {
    color: #848f99;
    font-size: 12px;
    font-weight: 500;
    display: block;
    margin-bottom: 7px; }

.form-input--button {
    text-align: right;
    margin-top: 20px; }

.alert {
    background-color: #fffede;
    border-radius: 4px;
    clear: both;
    color: #222b37;
    line-height: 1.25;
    padding: 15px;
    text-align: center;

    a {
        color: #646f79;
        border-bottom: 1px dotted #646f79;

        &:hover {
            border-bottom: 1px solid #646f79;
            text-decoration: none; } }

    p {
        margin-top: 5px;
        &:first-of-type {
            margin-top: 0; } }

    strong {
        font-weight: 500; } }

.alert--error {
    background-color: #ffedef; }

.input--error {
    display: block;
    margin-top: 6px;
    font-size: 12px;
    color: hsl(348, 100%, 61%); }

.files--error {
    margin-bottom: 10px;
    white-space: pre-line; }

.input--help {
    display: block;
    margin-top: 10px;

    a {
        border-bottom: 1px dotted #848f99;
        color: #848f99;
        font-size: 11px;
        text-align: left;

        &:hover {
            border-bottom: 1px solid #848f99;
            text-decoration: none; } } }
